.hf-news-article-title{border-top:5px solid #ee9435;padding-top:32px}.hf-news-article-title .title{-webkit-box-decoration-break:clone;-o-box-decoration-break:clone;box-decoration-break:clone;display:inline;line-height:3rem;padding-left:0;font-size:2.5rem;background-color:transparent !important;color:#363636}.hf-news-article-title .title:not(.is-spaced)+.subtitle{margin-top:0}.hf-is-lead{font-family:"Roboto Slab","Georgia",serif;font-size:1.25rem}.hf-is-lead.hf-overflay-base{background-color:rgba(255,255,255,0.9);padding:4px 6px;-webkit-box-shadow:2px 2px 9px rgba(0,0,0,0.2);box-shadow:2px 2px 9px rgba(0,0,0,0.2)}

.hf-wrapper{display:block;position:relative;min-height:220px}.hf-wrapper .title{margin-bottom:18px;line-height:2.4rem;background-color:#363636 !important;-webkit-box-shadow:2px 2px 9px rgba(0,0,0,0.2);box-shadow:2px 2px 9px rgba(0,0,0,0.2);padding:2px 7px;-webkit-box-decoration-break:clone;-o-box-decoration-break:clone;box-decoration-break:clone;display:inline-table}.hf-wrapper .title a{color:white !important}.hf-overflay-base{background:rgba(216,216,216,0.7);padding:0.5em 0.75em}.hf-overflay-base.hf-overlay-picture{background-color:transparent}.hf-overflay-base p,.hf-overflay-base .title,.hf-overflay-base .subtitle{color:rgba(0,0,0,0.7)}.hf-overflay-base .subtitle{background-color:rgba(255,255,255,0.9);-webkit-box-shadow:2px 2px 9px rgba(0,0,0,0.2);box-shadow:2px 2px 9px rgba(0,0,0,0.2);font-weight:bold;-webkit-box-decoration-break:clone;-o-box-decoration-break:clone;box-decoration-break:clone;padding:2px 7px;margin-bottom:5px !important}.hf-overlay-picture{position:absolute;bottom:0;left:0}

.hf-result[data-v-3e6a4abd]{text-align:center}.hf-result .title[data-v-3e6a4abd]{font-weight:bold;background-color:#ee9435 !important;color:#fff;padding:5px 7px;-webkit-box-decoration-break:clone;-o-box-decoration-break:clone;box-decoration-break:clone;display:inline;line-height:2.4rem}.hf-result .title a[data-v-3e6a4abd]{color:#fff}.hf-result .title a[data-v-3e6a4abd]:hover{color:#fff}.hf-result .subtitle[data-v-3e6a4abd]{margin-top:.75rem;font-weight:bold}

.hf-game-carousel-slide .columns{margin-left:.375rem;margin-right:.375rem}

.hf-topgame .hf-favourite-team-selection .dropdown{float:right !important}.hf-topgame .hf-favourite-team-selection a:not(.dropdown-item){size:.825rem;color:#D8D8D8}.hf-topgame .hf-favourite-team-selection a:not(.dropdown-item):hover{color:#ee9435}

.hf-is-focus-title[data-v-4abc6198]{background-color:#363636 !important;color:#fff !important;padding:.375rem .75rem;display:inline-block}@media (max-width: 768px){.hf-is-focus-title[data-v-4abc6198]{display:block}}.columns.is-gapless[data-v-4abc6198]{margin-top:-.75rem;margin-left:-.75rem;margin-right:-.75rem;margin-bottom:.75rem}

.hf-list[data-v-6499cc76]{font-family:"Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue","Helvetica","Arial",sans-serif}.hf-list .hf-player-birthday[data-v-6499cc76]{white-space:normal}.hf-list .hf-player-birthday-picture[data-v-6499cc76]{margin-right:.75rem;margin-top:2px;margin-bottom:2px}


/*# sourceMappingURL=hf-index.5e06db50.css.map*/